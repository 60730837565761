import React, { useEffect, useState } from 'react';
import './App.css'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import DoneIcon from '@mui/icons-material/Done';
import qs from 'qs'

function StayInfoCard({ defaultValues, callback }) {
  const [dateSelected, setDateSelected] = useState(dayjs(Date.now()));
  const [tableNo, setTableNo] = useState("");
  const [waiterName, setWaiterName] = useState("")
  const [firstVisit, setFirstVisit] = useState(false)
  // const [hasMounted, setHasMounted] = useState(false)
  const handleDateChange = (newDate) => { setDateSelected(newDate); };

  //useEffect(() => setHasMounted(true), [])

  useEffect(() => {
    setTableNo(defaultValues?.table_no || "")
    setWaiterName(defaultValues?.waiter_name || "")
    setFirstVisit(defaultValues?.first_visit || false)
  }, [defaultValues])

  useEffect(() => {
    callback("date_of_stay", dateSelected)
  }, [dateSelected])

  return <div className="card stay-info">
    <div className="title">Information about your stay</div>
    <div className="question">
      <div className="description" style={{ marginBottom: "0.5rem" }}></div>
      <div className='answer'>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDatePicker
            className="custom-date-picker"
            label="Date of visit"
            inputFormat="MM/DD/YYYY"
            value={defaultValues?.date_of_stay || dateSelected}
            onChange={handleDateChange}
            renderInput={(params) => <TextField {...params} size="small" fullWidth />}
          />
        </LocalizationProvider>
      </div>
    </div>
    <div className="question inline">
      <TextField value={tableNo} id="table-no" label="Table number" variant="outlined" size="small" fullWidth sx={{ marginBottom: "1rem" }} onChange={e => {
        setTableNo(e.target.value)
        callback("table_no", e.target.value)
      }} />
      <TextField value={waiterName} id="waiter-name" label="Waiter's name" variant="outlined" size="small" fullWidth sx={{ marginBottom: "1rem" }} onChange={e => {
        setWaiterName(e.target.value)
        callback("waiter_name", e.target.value)
      }} />
    </div>
    <div className="question inline">
      <div className="description">Was this your first visit to our restaurant?</div>
      <div>
        <FormControl fullWidth sx={{ maxWidth: "10rem" }}>
          <RadioGroup
            row
            value={firstVisit}
            aria-labelledby="demo-row-radio-buttons-group-label-2"
            name="row-radio-buttons-group-2"
            onChange={(e) => {
              setFirstVisit(e.target.value)
              callback("first_visit", e.target.value)
            }}
            sx={{ justifyContent: "space-between" }}
          >
            <FormControlLabel labelPlacement="end" sx={{ margin: "inherit" }} value="true" control={<Radio size="small" />} label="Yes" />
            <FormControlLabel labelPlacement="end" sx={{ margin: "inherit" }} value="no" control={<Radio size="small" />} label="No" />
          </RadioGroup>
        </FormControl>
      </div>
    </div>
  </div>
}


function CommentsCard({ defaultValues, callback }) {
  const [comments, setComments] = useState("")

  useEffect(() => {
    setComments(defaultValues?.comment || "")
  }, [defaultValues])

  return <div className="card comments">
    <div className="title">Comments and suggestions</div>
    <TextField
      id="customer-name"
      placeholder="Enter text"
      variant="outlined"
      size="small"
      fullWidth
      multiline
      value={comments}
      onChange={e => {
        setComments(e.target.value)
        callback("comment", e.target.value)
      }}
      rows={4} />
  </div>
}


function CustomerInfoCard({ defaultValues, callback }) {
  const [cname, setCname] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [invoice, setInvoice] = useState("")

  useEffect(() => {
    setCname(defaultValues?.name || "")
    setEmail(defaultValues?.email || "")
    setPhone(defaultValues?.phone || "")
    setInvoice(defaultValues?.invoice_no || "")
  }, [defaultValues])

  return <div className="card customer-info">
    <div className="title">Your contact information (optional)</div>
    <Box
      component="form"
      noValidate
      autoComplete='off'
      className="question">
      <div className="answer">
        <TextField value={cname} id="customer-name" label="Name" variant="outlined" size="small" fullWidth onChange={e => {
          setCname(e.target.value)
          callback("name", e.target.value)
        }} />
        <TextField value={email} id="customer-email" label="Email" variant="outlined" size="small" fullWidth onChange={e => {
          setEmail(e.target.value)
          callback("email", e.target.value)
        }} />
        <TextField value={phone} id="customer-phone" label="Phone number" variant="outlined" size="small" fullWidth onChange={e => {
          setPhone(e.target.value)
          callback("phone", e.target.value)
        }} />
        <TextField value={invoice} id="customer-invoice" label="Invoice number" variant="outlined" size="small" fullWidth sx={{ marginBottom: "0rem !important" }} onChange={e => {
          setInvoice(e.target.value)
          callback("invoice_no", e.target.value)
        }} />
      </div>
    </Box>
  </div >
}

function ExperienceRating({ defaultValues, callback }) {
  const [uKey, setUKey] = useState("")

  useEffect(() => {
    setUKey(Date.now()) // force refresh of components
  }, [defaultValues])

  return <div className="card experience">
    <div className="title" style={{ marginBottom: "1.5rem" }}>Rate your experience</div>
    {[
      ["Greeting & Seating", "greeting_and_seating"],
      ["Staff attitude", "staff_attitude"],
      ["Quality of food", "food_quality"],
      ["Variety of food", "food_variety"],
      ["Food presentation", "food_presentation"],
      ["Speed of service", "service_speed"],
      ["Efficiency of service", "service_efficiency"],
      ["Ambiance", "ambiance"],
      ["Cleanliness", "cleanliness"]
    ].map(field => <div className="question inline" key={field[1] + uKey}>
      <div className="description">{field[0]}</div>
      <FormControl className="answer" fullWidth sx={{ maxWidth: "30rem" }}>
        <RadioGroup
          row
          defaultValue={defaultValues ? defaultValues[field[1]] : null}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={e => callback(field[1], e.target.value)}
          sx={{ justifyContent: "space-between" }}
        >
          <FormControlLabel labelPlacement="end" sx={{ margin: "inherit" }} value="Excellent" control={<Radio size="small" />} label="Excellent" />
          <FormControlLabel labelPlacement="end" sx={{ margin: "inherit" }} value="Good" control={<Radio size="small" />} label="Good" />
          <FormControlLabel labelPlacement="end" sx={{ margin: "inherit" }} value="Average" control={<Radio size="small" />} label="Average" />
          <FormControlLabel labelPlacement="end" sx={{ margin: "inherit" }} value="Need Improvement" control={<Radio size="small" />} label={<div style={{ textAlign: "center" }}>
            <div style={{ display: "inline-block" }}>Need&#160;</div>
            <div style={{ display: "inline-block" }}>Improvement</div>
          </div>} />
        </RadioGroup>
      </FormControl>
    </div>)}
  </div>
}

function App() {
  const [successSend, setSuccessSend] = useState(false)
  const [sendError, setSendError] = useState("")
  const [loadingError, setLoadingError] = useState("")
  const [sending, setSending] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadedAnswers, setLoadedAnswers] = useState(null)

  const answerId = qs.parse(window.location.search, { ignoreQueryPrefix: true }).answerId || null

  var surveyAnswer = {}

  useEffect(() => {
    if (answerId) loadAnswer()
  }, [])

  const restaurantId = (() => {
    const parts = window.location.pathname.split("/")
    if (parts.length > 1) return parts[1]
    return null
  })()

  function updateAnswer(key, value) {
    surveyAnswer[key] = value
  }

  function loadAnswer() {
    setLoading(true)

    fetch(`https://api.takeaseat.io/private-survey/${answerId}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: "include"
    }).then(resp => {
      if (resp.status === 200) {
        resp.json().then(data => setLoadedAnswers(data[0].answers))
      } else {
        setLoadingError("Error, we could not get the answers. Please try again later.")
      }
      setLoading(false)
    })
  }

  function saveAnswer() {
    setSending(true)
    setSendError("")

    fetch(`https://api.takeaseat.io/survey/${restaurantId}`, {
      method: 'put',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ "answers": surveyAnswer })
    }).then(data => {
      setSending(false)
      if (data.status === 200) {
        setSuccessSend(true)
      } else {
        setSuccessSend(false)
        setSendError("Error, we could not send your answers. Please try again later.")
      }
    })
  }

  return (
    <div style={{
      padding: "1rem",
      display: "flex"
    }}
      className="App"
    >
      {loading && <div
        style={{ backdropFilter: "blur(3px)", zIndex: "2", position: "fixed", inset: "0", background: "rgba(250, 250, 250, 0.7)", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center" }}
      ><span>Loading...</span></div>}
      {loadingError && <div
        style={{ backdropFilter: "blur(3px)", zIndex: "2", position: "fixed", inset: "0", background: "rgba(250, 250, 250, 0.7)", fontWeight: "bold", display: "flex", justifyContent: "center", alignItems: "center", color: "red" }}
      ><span>{loadingError}</span></div>}
      <div className="content">
        <h1 style={{ marginBottom: "3rem" }}>Customer satisfaction survey</h1>
        <StayInfoCard callback={updateAnswer} defaultValues={loadedAnswers} />
        <ExperienceRating callback={updateAnswer} defaultValues={loadedAnswers} />
        <CommentsCard callback={updateAnswer} defaultValues={loadedAnswers} />
        <CustomerInfoCard callback={updateAnswer} defaultValues={loadedAnswers} />
        {loadedAnswers ? <></> : (
          successSend ? <div style={{
            padding: "1rem",
            textAlign: "center",
            marginBottom: "6rem"
          }}>
            <div style={{ color: "#2cb052", marginBottom: "1rem", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "bold" }}>Success <DoneIcon /></div>
            <div><b>Thank you for your answers!</b></div>
          </div> : <><LoadingButton
            id="send-button"
            loading={sending}
            variant="outlined"
            size="small"
            fullWidth
            sx={{
              display: "flex",
              padding: "1rem",
              background: sending ? "white" : "#2f4858",
              marginBottom: "0.5rem",
              borderRadius: "4px",
              cursor: "pointer",
              boxShadow: "1px 1px 2px 1px lightgray"
            }}
            onClick={saveAnswer}>
            <div style={{
              width: "100%",
              color: "white",
              display: "flex",
              alignItems: "center",
              justifyContent: "center"
            }}>
              <b>Send answers</b>
              <span style={{ marginLeft: "1rem", display: "flex" }}><SendIcon /></span>
            </div>
          </LoadingButton>
            {sendError && <div style={{ color: "red", marginBottom: "1rem" }}>{sendError}</div>}
            <div style={{ marginBottom: "6rem" }}>
              You agree to the <a target="_blank" href="https://www.privacypolicygenerator.info/live.php?token=wGGtkHFI3MdKx5HpDWSZYKwI42xkUbse" rel="nofollow">
                privacy policy
              </a>
            </div>
          </>)}
      </div>
    </div >
  );
}

export default App;
